<template>
	<div class="groupManage">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">录入时间:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">经办人:</div>
					<div class="item-input">
						<el-input v-model="searchForm.handler" placeholder="请输入经办人" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择">
							<el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">费用科目:</div>
					<div class="item-input">
						<el-select v-model="searchForm.fee_type" filterable placeholder="请选择收入科目" style="width: 100%;">
							<el-option v-for="item in feeTypeList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">付款方式:</div>
					<div class="item-input">
						<el-select v-model="searchForm.pay_type" filterable placeholder="请选择支付方式" style="width: 100%;">
							<el-option v-for="item in payList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click="dialogVisible = true, dialogType = 1, form = {
                    target: []
                }">新增费用</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="录入时间" prop="created_at" align="center" width="160"></el-table-column>
				<el-table-column label="记账日期" prop="date" align="center" width="120"></el-table-column>
				<el-table-column label="费用科目" prop="fee_type_item.name" align="center" width="140"></el-table-column>
				<el-table-column label="付款方式" prop="pay_type_text" align="center" width="140"></el-table-column>
				<el-table-column label="费用金额" prop="amount" align="center" width="140"></el-table-column>
				<el-table-column label="经办人" prop="handler" align="center" width="120"></el-table-column>
				<el-table-column label="状态" prop="status" align="center" width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">待确认</span>
						<span style="color:#67C23A" v-if="scope.row.status == 1">已入账</span>
						<span style="color:#F56C6C" v-if="scope.row.status == 9">已撤销</span>
					</template>
				</el-table-column>
				<el-table-column label="说明" prop="remark" align="center"></el-table-column>

				<el-table-column label="操作" align="center" width="300">
					<template slot-scope="scope">
						<el-button type="warning" size="mini" v-if="scope.row.status == 0"
							@click="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
						<el-button type="primary " v-if="scope.row.status == 0" size="mini"
							@click="confirmFn(scope.row)">入账</el-button>
						<el-button type="danger" v-if="scope.row.status == 0" size="mini"
							@click="cancelFn(scope.row)">撤销</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="30%">
			<el-form ref="form" :model="form" label-width="120px">
				<el-form-item label="记账日期" required>
					<el-date-picker v-model="form.date" type="date" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
						placeholder="选择日期" :picker-options="pickerOptions" style="width: 100%;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="费用科目" required>
					<el-select v-model="form.fee_type" filterable placeholder="请选择费用科目" style="width: 100%;">
						<el-option v-for="item in feeTypeList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="自定义科目" v-if="!form.fee_type || form.fee_type == 0">
					<el-input type="input" v-model="form.fee_name" placeholder="请输入自定义科目" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="付款方式" required>
					<el-select v-model="form.pay_type" filterable placeholder="请选择付款方式" style="width: 100%;">
						<el-option v-for="item in payList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="付款金额" required>
					<el-input type="input" v-model="form.amount" placeholder="请输入付款金额"></el-input>
				</el-form-item>
				<el-form-item label="经办人" required>
					<el-input type="input" v-model="form.handler" placeholder="请输入经办人" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="说明">
					<el-input type="textarea" v-model="form.remark" placeholder="请输入说明" maxlength="200"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click="saveFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
				dateVal: [],
				searchForm: {},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				statusOptions: [{
						id: 0,
						name: '待确认'
					},
					{
						id: 1,
						name: '已入账'
					},
					{
						id: 9,
						name: '已撤销'
					}
				],
				feeTypeList: [],
				// 新增、编辑弹窗
				payList: [{
						id: 1,
						name: '微信转账'
					},
					{
						id: 3,
						name: '支付宝转账'
					},
					{
						id: 30,
						name: '线下现金'
					},
					{
						id: 31,
						name: '线下转账'
					},
					{
						id: 32,
						name: '线下扫码'
					},
					{
						id: 39,
						name: '线下其他'
					}
				],
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					name: ""
				}
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentMonthBegin(), this.getCurrentDate() + ' ' + '23:59:59']

			this.getList()
			this.getIncomeTypeList()

		},
		methods: {
			getIncomeTypeList() {
				this.$http.post(`/erp/v1/financial/fee/type/all`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.feeTypeList = data
						this.feeTypeList.push({
							id: 0,
							name: "自定义"
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			getList() {
				this.$http.post(`/erp/v1/financial/fee/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 编辑弹窗
			openDialog(item) {
				this.form = {
					...item
				}
			},
			// 确定
			saveFn() {
				if ((!this.form.fee_type || this.form.fee_type == 0) && this.form.fee_name == "") {
					this.$message.error("请输入自定义科目");
					return;
				}
				let url = this.dialogType == 1 ? '/erp/v1/financial/fee/add' :
					'/erp/v1/financial/fee/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			//撤销
			cancelFn(item) {
				this.$confirm(`确定撤销${item.amount}元费用支出吗, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/financial/fee/cancel`, {
						id: item.id,
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			//入账
			confirmFn(item) {
				this.$confirm(`确定入账${item.amount}元费用支出吗, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/financial/fee/confirm`, {
						id: item.id,
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 获取当前月份的起始时间
			getCurrentMonthBegin() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-01 00:00:00`;
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
		},
	}
</script>

<style lang="scss" scoped>
	.groupManage {
		.notice {
			background: #fff;
			margin-bottom: 20px;
			padding: 20px;
			border-radius: 10px;
		}
	}
</style>